<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      :centered="true"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Document Library Edit</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmit()"
          :disabled="isLoader"
          >Update
          <CSpinner v-if="isLoader" class="spinner-border-sm text-white m-1" />
        </CButton>
      </template>
      <div>
        <ValidationObserver ref="editDocumentLib" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)" name="editDocumentLib">
            <CRow>
              <CCol md="6">
                <CRow class="mb-2">
                  <label
                    name="lbl_category"
                    class="col-lg-12 col-md-12 required"
                    >Document Category</label
                  >
                  <CCol md="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="category"
                        :options="
                          options && options['category']
                            ? options['category']
                            : []
                        "
                        :value="uploadData.category"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                        :disabled="false"
                        @input="handleselect"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>

              <CCol md="6">
                <CRow class="row mb-2">
                  <label name="lbl_dtype" class="col-lg-12 col-md-12 required"
                    >Document Type</label
                  >
                  <div class="col-lg-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="customer_document_type_id"
                        :options="
                          options && options['customer_document_type']
                            ? options['customer_document_type']
                            : []
                        "
                        :value="uploadData.customer_document_type_id"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :disabled="false"
                        :error="errors[0]"
                        @input="handleselect"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="row mb-2">
                  <label name="lbl_ctype" class="col-lg-12 col-md-12 required"
                    >Candidate Type
                  </label>
                  <div class="col-lg-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="candidate_type_ids"
                        :options="
                          options && options['candidate_Type']
                            ? options['candidate_Type']
                            : []
                        "
                        :value="uploadData.candidate_type_ids"
                        :taggable="false"
                        :multiple="true"
                        :clearable="false"
                        :error="errors[0]"
                        :disabled="false"
                        @input="handleselectforcandidateType"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="mb-2">
                  <label name="lbl_name" class="col-lg-12 col-md-12 required"
                    >Description</label
                  >
                  <CCol md="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="document_description"
                        :value="uploadData.document_description"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="mb-2">
                  <label name="lbl_rtype" class="col-lg-12 col-md-12"
                    >Request Type</label
                  >
                  <CCol md="12">
                    <RadioButton
                      name="request_type"
                      :value="uploadData.request_type"
                      :options="
                        options && options['request_types']
                          ? options['request_types']
                          : []
                      "
                      @change="handleRadioCustom"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6" v-if="flags.formshow">
                <CRow class="mb-2">
                  <label name="lbl_rtype" class="col-lg-12 col-md-12"
                    >Form Type</label
                  >
                  <CCol md="12">
                    <RadioButton
                      name="form_type"
                      :value="uploadData.form_type"
                      :options="
                        options && options['form_types']
                          ? options['form_types']
                          : []
                      "
                      @change="handleRadioCustom"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6" v-if="flags.onlineform">
                <CRow class="mb-2">
                  <label name="lbl_url" class="col-lg-12 col-md-12 required"
                    >URL</label
                  >
                  <CCol md="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="online_form_url"
                        :value="uploadData.online_form_url"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="12" >
                <CRow class="md-2" v-if="flags.signatureshow">
                  <CCol md="6">
                    <label>
                      <input
                        id="sign_req"
                        name="sign_req"
                        type="checkbox"
                        class="cursor-pointer mx-1"
                        @input="
                          handleRadioCustom('sign_req', $event.target.checked)
                        "
                        :value="uploadData.sign_req"
                        :checked="uploadData.sign_req"
                        :disabled="flags.disableSign"
                      />
                      Signature Required
                    </label>
                  </CCol>
                </CRow>
                <CRow class="md-2" v-if="flags.uploaddocsshow">
                  <CCol
                    md="6"
                    v-if="
                      uploadData.old_org_doc
                        ? uploadData.old_org_doc.isDeleted
                        : true
                    "
                  >
                    <label name="lbl_upload-document" class="required"
                      >Upload Document:</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <input
                        id="document-upload"
                        name="upload-document"
                        type="file"
                        ref="fileref"
                        @click="OnClickDocument($event)"
                        :accept="arraytoComma(fileType)"
                        @change="selectedFile"
                      />
                    </div>
                  </CCol>
                  <CCol md="12" v-else-if="uploadData.new_org_doc">
                    <span
                      class="text-primary"
                      style="cursor: pointer; font-size: 12px"
                    >
                      {{ uploadData.new_org_doc.fileName }}
                      <a
                        class="btn hand-icon action-button"
                        @click="deleteDocuments()"
                      >
                        <i class="fas fa-trash ml-3"></i>
                      </a>
                    </span>
                  </CCol>
                  <CCol md="12" v-else>
                    <span
                      class="text-primary"
                      style="cursor: pointer; font-size: 12px"
                    >
                      {{ uploadData.old_org_doc.document_name }}
                      <a
                        class="btn hand-icon action-button"
                        @click="
                          deleteDocuments(
                            uploadData.old_org_doc.organisation_document_id
                          )
                        "
                      >
                        <i class="fas fa-trash ml-3"></i>
                      </a>
                    </span>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
    <div v-if="cropperModal.isShowPopup">
      <CropperModal
        :isShowPopup="cropperModal.isShowPopup"
        :fileData="cropperModal.fileData"
        modalColor="primary"
        :buttonLabel="cropperModal.buttonLabel"
        @modalCallBack="cropperModalCallBack"
        :aspectRatio="cropperModal.aspectRatio"
      />
    </div>
  </div>
</template>
<script>
import RadioButton from "@/components/reusable/Fields/RadioButton";
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput.vue";
import CheckBox from "@/components/reusable/Fields/CheckBox";
import CropperModal from "@/components/reusable/CropperModal";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
    documentInfo: {
      type: Object,
      default: () => {},
    },
    isLoader: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    Select,
    TextInput,
    RadioButton,
    CheckBox,
    CropperModal,
  },
  data() {
    return {
      uploadData: {},
      payload: {},
      flags: {
        showdocs: false,
        signatureshow: false,
        formshow: false,
        onlineform: false,
        offlineform: false,
        uploaddocsshow: false,
      },
      cropperModal: {
        isShowPopup: false,
        fileData: null,
        buttonLabel: "Apply",
        aspectRatio: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      "customerDocTypeCategory",
      "getOrgIDFromAccessToken",
      "customerDocumentTypes",
      "customerDocumentLibrary",
      "getCustomerId",
      "candidateType",
    ]),
    getcandidateTypeAll() {
      const candidateType = [...this.candidateType];
      return (
        (candidateType.length && [
          { label: "All", code: 0 },
          ...candidateType,
        ]) ||
        []
      );
    },
    options() {
      return {
        category: this.category || [],
        customer_document_type: this.customerDocumentTypesByCategory || [],
        request_types: [
          { id: "Document", label: "Document" },
          { id: "Upload Request", label: "Upload Request" },
          { id: "Form", label: "Form" },
        ],
        candidate_Type: this.getcandidateTypeAll || [],
        form_types: [
          { id: "Online Form", label: "Online Form" },
          { id: "Offline Form", label: "Offline Form" },
        ],
      };
    },
    category() {
      return (
        this.customerDocTypeCategory &&
        this.customerDocTypeCategory.map((v) => ({
          code: v,
          label: _.startCase(v),
          category: v,
        }))
      );
    },
    customerDocumentTypesByCategory() {
      return (
        (this.uploadData?.category &&
          this.customerDocumentTypes &&
          this.customerDocumentTypes.length &&
          this.customerDocumentTypes
            .filter((v) => v?.category === this.uploadData?.category?.code)
            .map((v) => ({
              code: v?.customer_document_type_id,
              label: v?.type_name,
              description: v?.description,
              allowed_extensions: v?.allowed_extensions,
            }))) ||
        []
      );
    },
    fileType() {
      return (
        this.uploadData?.customer_document_type_id?.allowed_extensions.map(
          (v) => v.toLowerCase()
        ) || []
      );
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "deleteOrganisationDocumentFile",
    ]),
    getExtension(filename) {
      let parts = filename.split(".");
      return parts[parts.length - 1];
    },
    arraytoComma(d) {
      return d?.map((v) => "." + v).join(", ");
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
    handleselect(name, value) {
      Vue.set(this.uploadData, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
      if (name === "document_title") {
        this.handleInput("comments", value?.title_description);
      }
    },
    handleInput(name, value) {
      Vue.set(this.uploadData, name, value);
      Vue.set(this.payload, name, value);
    },
    handleselectforcandidateType(name, value) {
      let last_index = value[value.length - 1];
      let val = null;
      if (last_index?.label == "All" || !last_index) {
        Vue.set(this.uploadData, name, [{ code: 0, label: "All" }]);
        val = this.candidateType.map((v) => v.code).filter((v) => v != 0);
        this.payload = {
          ...this.payload,
          [name]: val,
        };
      } else {
        if (value[0].label == "All") value = value.slice(1);
        val = value.map((v) => {
          return v.id || v.code || value;
        });
        Vue.set(this.uploadData, name, value);
        this.payload = {
          ...this.payload,
          [name]: val,
        };
      }
    },
    handleRadioCustom(name, value) {
      let v = null;
      if (value && (value.id === false || value.code === false)) {
        v = false;
      } else {
        v = value?.id || value?.code || value;
      }
      Vue.set(this.uploadData, name, v);
      this.payload = {
        ...this.payload,
        [name]: v,
      };

      if (name === "request_type") {
        this.handleRadioCustom("form_type", null);
        this.handleRadioCustom("sign_req", false);
        this.handleInput("online_form_url", null);
      }
      if (name === "request_type" && value) {
        switch (value) {
          case "Document":
            this.flags = {
              showdocs: false,
              signatureshow: true,
              formshow: false,
              onlineform: false,
              offlineform: false,
              uploaddocsshow: true,
              offlineshow: false,
              disableSign: false,
            };
            break;
          case "Upload Request":
            this.flags = {
              showdocs: false,
              signatureshow: true,
              formshow: false,
              onlineform: false,
              offlineform: false,
              uploaddocsshow: false,
              offlineshow: false,
              disableSign: true,
            };
            this.handleRadioCustom("sign_req", true);
            break;
          case "Form":
            this.flags = {
              showdocs: false,
              signatureshow: false,
              formshow: true,
              offlineshow: true,
              onlineform: false,
              offlineform: true,
              uploaddocsshow: false,
              disableSign: false,
            };
            break;
          default:
            this.flags = {
              showdocs: false,
              signatureshow: false,
              formshow: false,
              offlineshow: false,
              onlineform: false,
              offlineform: false,
              uploaddocsshow: false,
              disableSign: false,
            };
        }
      } else if (name === "form_type" && value) {
        switch (value) {
          case "Online Form":
            this.flags = {
              showdocs: false,
              signatureshow: false,
              formshow: true,
              onlineform: true,
              offlineform: false,
              uploaddocsshow: false,
              offlineshow: false,
              disableSign: false,
            };
            break;
          case "Offline Form":
            this.flags = {
              showdocs: false,
              signatureshow: true,
              formshow: true,
              onlineform: false,
              offlineform: true,
              uploaddocsshow: true,
              offlineshow: false,
              disableSign: false,
            };
          default:
            this.flags = {
              showdocs: false,
              signatureshow: false,
              formshow: false,
              offlineshow: false,
              onlineform: false,
              offlineform: false,
              uploaddocsshow: false,
              disableSign: false,
            };
        }
      }
    },
    selectedFile(event) {
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        const fileExt = this.getExtension(file.name);
        const fileTypeString = this.fileType;
        if (fileTypeString.includes(fileExt)) {
          if (file.type.startsWith("image/")) {
            this.cropperModal.fileData = file;
            this.cropperModal.isShowPopup = true;
          } else {
            this.payload = {
              ...this.payload,
              new_org_doc: {
                file,
                fileName: file?.name,
              },
            };
          }
        } else {
          let fileTypeError = this.arraytoComma(fileTypeString);
          this.$refs.fileref.value = "";
          this.showToast({
            class: "bg-danger text-white",
            message: `Allowed File Type ${fileTypeError}`,
          });
          return;
        }
      } else {
        this.$refs.fileref.value = "";
        this.showToast({
          class: "bg-danger text-white",
          message: "File too Big, please select a file less than 2mb",
        });
        return;
      }
    },
    handleRadioCustomforsign(name, value) {
      Vue.set(this.uploadData, name, value);
      Vue.set(this.payload, name, value);
    },
    deleteDocuments(organisation_document_id) {
      if (organisation_document_id) {
        const old_org_doc = {
          ...this.uploadData.old_org_doc,
          isDeleted: true,
        };
        Vue.set(this.uploadData, "old_org_doc", old_org_doc);
        this.payload = {
          ...this.payload,
          old_org_doc,
        };
      } else {
        this.payload = {
          ...this.payload,
          new_org_doc: null,
        };
      }
    },
    async OnClickDocument(e) {
      const isValid = await this.$refs.editDocumentLib.validate();
      if (!isValid) {
        e.preventDefault();
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
          autohide: 2000,
        });
        setTimeout(() => {
          this.$refs.editDocumentLib.reset();
          this.$refs.editDocumentLib.errors.clear;
        }, 2000);
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.editDocumentLib.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
          autohide: 2000,
        });
        setTimeout(() => {
          this.$refs.editDocumentLib.reset();
          this.$refs.editDocumentLib.errors.clear;
        }, 2000);
        return;
      }
      const isFileAvailable =
        this.payload?.new_org_doc?.file ||
        this.payload?.old_org_doc?.isDeleted === false;
      const isFileRequired =
        ["Document"].includes(this.payload.request_type) ||
        (this.payload.request_type === "form" &&
          this.payload.form_type === "offline");
      if (isFileRequired && !isFileAvailable) {
        this.showToast({
          class: "bg-danger text-white",
          message: "No File choosen!",
        });
        return;
      } else {
        const candidate_type_ids =
          this.uploadData?.candidate_type_ids[0].label == "All"
            ? this.candidateType
                ?.filter((val) => val?.label != "All")
                ?.map((val) => val.code)
            : this.uploadData?.candidate_type_ids?.length
            ? this.uploadData?.candidate_type_ids?.map((val) => val.code)
            : [];
        this.payload = {
          ...this.payload,
          candidate_type_ids,
        };
        if (!isFileRequired) {
          this.payload.new_org_doc = null;
          this.payload.old_org_doc
            ? (this.payload.old_org_doc.isDeleted = true)
            : null;
        }
        this.modalCallBack(true, this.payload);
      }
    },
    cropperModalCallBack(action, data) {
      if (action === false) {
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
        this.$refs.fileref.value = "";
      } else {
        let { fileBlob:file, fileName } = data;
        this.payload = {
              ...this.payload,
              new_org_doc: {
                file,
                fileName,
              },
            };
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
      }
    },
    populateData(data) {
      const { item } = data;
      const category = this.category.filter(
        ({ code }) => code === item?.category
      );
      this.handleselect("category", category.length ? category[0] : null);
      const customer_document_type_id =
        this.customerDocumentTypesByCategory.filter(
          ({ code }) => code === item?.customer_document_type_id
        );
      this.handleselect(
        "customer_document_type_id",
        customer_document_type_id.length ? customer_document_type_id[0] : null
      );
      const candidateType =
        this.candidateType?.length == item?.candidate_type_ids?.length
          ? [{ code: 0, label: "All" }]
          : this.candidateType.filter((val) =>
              item?.candidate_type_ids?.includes(val.code)
            ) || [];
      this.handleselectforcandidateType("candidate_type_ids", candidateType);
      this.handleInput("document_description", item?.document_description);
      this.handleRadioCustom("request_type", item?.request_type);
      this.handleRadioCustom("sign_req", item?.sign_req);
      this.handleInput("online_form_url", item?.online_form_url);
      const form_type = item?.request_type === "Form"?(item?.online_form_url ? "Online Form" : "Offline Form"):null;
      this.handleRadioCustom("form_type", form_type);
      // this.payload.fileName = data?.name;
      if (item?.organisation_document_id) {
        const old_org_doc = {
          ...item.organisation_document,
          isDeleted: false,
        };
        Vue.set(this.uploadData, "old_org_doc", old_org_doc);
        this.payload = {
          ...this.payload,
          old_org_doc,
        };
      }
      this.payload.document_library_id = item?.document_library_id;
      // this.handleRadioCustom("form_radio_process");
    },
  },
  mounted() {
    const data = this.documentInfo;
    this.populateData(data);
  },
};
</script>