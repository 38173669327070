<template>
  <div class="position">
    <CDataTable
      :striped="true"
      :bordered="true"
      :small="true"
      :items="items"
      :fields="fieldsCustom"
      class="p-2 border"
      :tableFilter="{ label: 'Search', placeholder: 'Type for search' }"
    >
      <template #action="{ item }">
        <td class="py-2">
          <a class="cursor-pointer" v-if="!item.reserved&&item.item.is_custom_type">
            <i class="fas fa-edit ml-3" @click="openEditModal(item)"></i>
          </a>
          <a class="cursor-pointer" v-if="!item.reserved&&item.item.is_custom_type">
            <i
              class="fas fa-trash ml-3"
              @click="openConfirmationModal(item)"
            ></i>
          </a>
        </td>
      </template>
    </CDataTable>
    <Modal
      :modalTitle="confirmationModal.modalTitle"
      :modalColor="confirmationModal.modalColor"
      :modalContent="confirmationModal.message"
      :isShowPopup="confirmationModal.isShowPopup"
      :buttons="confirmationModal.buttons"
      :modalCallBack="confirmationModalCallBack"
      :closeOnBackdrop="false"
      v-if="confirmationModal.isShowPopup"
    />
  </div>
</template>
<script>
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select";
import TextInput from "@/components/reusable/Fields/TextInput";
import TextAreaInput from "@/components/reusable/Fields/TextareaInput";
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/reusable/Modal";
import _ from "lodash";
import m from "moment";
export default {
  props: {
    customerDocumentTypes: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Select,
    TextInput,
    TextAreaInput,
    Modal,
  },
  data() {
    return {
      fields: [
        {
          key: "provider_label",
          label: "Provider",
          _style: "width:20%",
          sorter: true,
          filter: true,
        },
        {
          key: "system",
          label: "Pre-Set/Custom",
          _style: "width:20%",
          sorter: true,
          filter: true,
        },

        {
          key: "document_type",
          label: "Type",
          _style: "width:20%",
          sorter: true,
          filter: true,
        },
        {
          key: "allowed_extensions_str",
          label: "Allowed Formats",
          _style: "width:20%",
          sorter: true,
          filter: true,
        },
        {
          key: "description",
          lable: "Description",
          _style: "width:30%",
          sorter: true,
          filter: true,
        },
        {
          key: "created_on",
          lable: "Date Added",
          _style: "width:10%",
          sorter: true,
          filter: true,
        },
        {
          key: "action",
          label: "Edit/Delete",
          _style: "width:10%",
          sorter: false,
          filter: false,
        },
      ],
      modalData: {
        isShowPopup: false,
        isEdit: false,
      },
      uploadData: {},
      payload: {},
      confirmationModal: {
        customer_document_type_id: null,
        modalTitle: "Confirmation",
        modalColor: "danger",
        isShowPopup: false,
        message: "",
        buttons: ["Cancel", "Confirm"],
      },
    };
  },
  computed: {
    ...mapGetters([
      "customerDocTypeCategory",
      "documentExtensions",
      "getOrgIDFromAccessToken",
      "getIsRecruiterRole",
    ]),
    fieldsCustom() {
      if (this.getIsRecruiterRole)
        return this.fields.filter(({ key }) => key != "action");
      return this.fields;
    },
    options() {
      return {
        category: this.category || [],
        extension: this.documentExtensions || [],
      };
    },
    category() {
      return (
        this.customerDocTypeCategory &&
        this.customerDocTypeCategory.map((v) => ({
          code: v,
          label: _.startCase(v),
          category: v,
        }))
      );
    },
    items() {
      return (
        (this.customerDocumentTypes &&
          this.customerDocumentTypes
            .map((v) => ({
              customer_document_type_id: v?.customer_document_type_id,
              category: _.startCase(v?.category),
              provider: v?.provider,
              provider_label:
                v?.provider?.map((val) => _.startCase(val)).join(", ") || "--",
              system: v?.is_custom_type ? "Custom" : "Pre-set",
              document_type: v?.type_name,
              description: v?.description || "--",
              allowed_extensions_str: v?.allowed_extensions.join(", ") || "--",
              created_on: this.formatDate(v?.created_on) || "--",
              reserved: v?.reserved,
              item: v,
            }))
            .sort((a, b) => new Date(b.created_on) - new Date(a.created_on))) ||
        []
      );
    },
    isCustomDocumentType() {
      let res = false;
      if (this.uploadData?.type_name)
        res =
          this.uploadData?.type_name.toLowerCase().includes("custom") || false;
      return res;
    },
  },
  methods: {
    ...mapActions([
      "createCustomerDocType",
      "updateCustomerDocType",
      "deleteCustomerDocType",
    ]),
    formatDate(date) {
      if (date) return m(date).format("DD MMM YYYY");
      return null;
    },
    openEditModal(data) {
      const { item } = data;
      this.$emit("classificationEdit", item);
    },
    modalCallBack(action) {
      this.modalData.isShowPopup = action;
    },
    handleselect(name, value) {
      Vue.set(this.uploadData, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
    },
    handleRadio(name, value) {
      let v = null;
      if (value && (value.id === false || value.code === false)) {
        v = false;
      } else {
        v = value?.id || value?.code || value;
      }
      Vue.set(this.uploadData, name, v);
      this.payload = {
        ...this.payload,
        [name]: v,
      };
    },
    handleInput(name, value) {
      Vue.set(this.uploadData, name, value);
      Vue.set(this.payload, name, value);
      if (
        name === "type_name" &&
        this.isCustomDocumentType &&
        this.uploadData.is_custom_type == undefined
      ) {
        this.handleRadio("is_custom_type", true);
      }
    },
    handleChangeMultiSelectAsArray(name, value) {
      Vue.set(this.uploadData, name, value);
      this.payload = {
        ...this.payload,
        [name]: value
          ? value.map((v) => {
              return v.id || v.code || v;
            })
          : null,
      };
    },
    async onSubmit() {
      const isValid = await this.$refs.formDocumentType.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
          autohide: 2000,
        });
      }
      this.payload = {
        ...this.payload,
        organisation_id: this.getOrgIDFromAccessToken,
      };
      let appendAction = [];
      if (this.modalData.isEdit && this.uploadData.customer_document_type_id) {
        this.payload = {
          ...this.payload,
          customer_document_type_id: this.uploadData?.customer_document_type_id,
        };
        appendAction = [this.updateCustomerDocType(this.payload)];
      } else {
        appendAction = [this.createCustomerDocType(this.payload)];
      }
      Promise.all(appendAction).then((res) => {
        this.modalData.isShowPopup = false;
        this.uploadData = {};
        this.payload = {};
        this.modalData.isEdit = false;
      });
    },
    openConfirmationModal(data) {
      this.confirmationModal.customer_document_type_id =
        data?.customer_document_type_id;
      this.confirmationModal.message = `Do you want to delete this document type - ${data?.document_type} ?`;
      this.confirmationModal.isShowPopup = true;
    },
    confirmationModalCallBack(action) {
      if (action === "Confirm") {
        this.deleteCustomerDocType(
          this.confirmationModal.customer_document_type_id
        ).then((res) => {
          this.confirmationModal = {
            customer_document_type_id: null,
            modalTitle: "Confirmation",
            modalColor: "danger",
            isShowPopup: false,
            message: "",
            buttons: ["Cancel", "Confirm"],
          };
        });
      } else {
        this.confirmationModal = {
          customer_document_type_id: null,
          modalTitle: "Confirmation",
          modalColor: "danger",
          isShowPopup: false,
          message: "",
          buttons: ["Cancel", "Confirm"],
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.end-1 {
  right: 1.25rem;
}

.index-1 {
  z-index: 1000;
}
</style>
