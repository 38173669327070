var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CModal',{attrs:{"color":"primary","show":_vm.isShowPopup,"close-on-backdrop":false,"centered":true,"size":"lg"},on:{"update:show":function($event){_vm.isShowPopup=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',{staticClass:"modal-title"},[_vm._v("Document Library Edit")]),_c('CButtonClose',{staticClass:"text-black",on:{"click":function($event){return _vm.modalCallBack(false)}}})]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"px-4",attrs:{"type":"button","variant":"outline","color":"primary"},on:{"click":function($event){return _vm.modalCallBack(false)}}},[_vm._v("Close")]),_c('CButton',{staticClass:"px-4",attrs:{"type":"button","color":"primary","disabled":_vm.isLoader},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v("Update "),(_vm.isLoader)?_c('CSpinner',{staticClass:"spinner-border-sm text-white m-1"}):_vm._e()],1)]},proxy:true}])},[_c('div',[_c('ValidationObserver',{ref:"editDocumentLib",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"name":"editDocumentLib"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"mb-2"},[_c('label',{staticClass:"col-lg-12 col-md-12 required",attrs:{"name":"lbl_category"}},[_vm._v("Document Category")]),_c('CCol',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"category","options":_vm.options && _vm.options['category']
                          ? _vm.options['category']
                          : [],"value":_vm.uploadData.category,"taggable":false,"multiple":false,"clearable":false,"error":errors[0],"disabled":false},on:{"input":_vm.handleselect}})]}}],null,true)})],1)],1)],1),_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"row mb-2"},[_c('label',{staticClass:"col-lg-12 col-md-12 required",attrs:{"name":"lbl_dtype"}},[_vm._v("Document Type")]),_c('div',{staticClass:"col-lg-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"customer_document_type_id","options":_vm.options && _vm.options['customer_document_type']
                          ? _vm.options['customer_document_type']
                          : [],"value":_vm.uploadData.customer_document_type_id,"taggable":false,"multiple":false,"clearable":false,"disabled":false,"error":errors[0]},on:{"input":_vm.handleselect}})]}}],null,true)})],1)])],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"row mb-2"},[_c('label',{staticClass:"col-lg-12 col-md-12 required",attrs:{"name":"lbl_ctype"}},[_vm._v("Candidate Type ")]),_c('div',{staticClass:"col-lg-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"candidate_type_ids","options":_vm.options && _vm.options['candidate_Type']
                          ? _vm.options['candidate_Type']
                          : [],"value":_vm.uploadData.candidate_type_ids,"taggable":false,"multiple":true,"clearable":false,"error":errors[0],"disabled":false},on:{"input":_vm.handleselectforcandidateType}})]}}],null,true)})],1)])],1),_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"mb-2"},[_c('label',{staticClass:"col-lg-12 col-md-12 required",attrs:{"name":"lbl_name"}},[_vm._v("Description")]),_c('CCol',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"document_description","value":_vm.uploadData.document_description,"error":errors[0]},on:{"input":_vm.handleInput}})]}}],null,true)})],1)],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"mb-2"},[_c('label',{staticClass:"col-lg-12 col-md-12",attrs:{"name":"lbl_rtype"}},[_vm._v("Request Type")]),_c('CCol',{attrs:{"md":"12"}},[_c('RadioButton',{attrs:{"name":"request_type","value":_vm.uploadData.request_type,"options":_vm.options && _vm.options['request_types']
                        ? _vm.options['request_types']
                        : []},on:{"change":_vm.handleRadioCustom}})],1)],1)],1),(_vm.flags.formshow)?_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"mb-2"},[_c('label',{staticClass:"col-lg-12 col-md-12",attrs:{"name":"lbl_rtype"}},[_vm._v("Form Type")]),_c('CCol',{attrs:{"md":"12"}},[_c('RadioButton',{attrs:{"name":"form_type","value":_vm.uploadData.form_type,"options":_vm.options && _vm.options['form_types']
                        ? _vm.options['form_types']
                        : []},on:{"change":_vm.handleRadioCustom}})],1)],1)],1):_vm._e(),(_vm.flags.onlineform)?_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"mb-2"},[_c('label',{staticClass:"col-lg-12 col-md-12 required",attrs:{"name":"lbl_url"}},[_vm._v("URL")]),_c('CCol',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"online_form_url","value":_vm.uploadData.online_form_url,"error":errors[0]},on:{"input":_vm.handleInput}})]}}],null,true)})],1)],1)],1):_vm._e(),_c('CCol',{attrs:{"md":"12"}},[(_vm.flags.signatureshow)?_c('CRow',{staticClass:"md-2"},[_c('CCol',{attrs:{"md":"6"}},[_c('label',[_c('input',{staticClass:"cursor-pointer mx-1",attrs:{"id":"sign_req","name":"sign_req","type":"checkbox","disabled":_vm.flags.disableSign},domProps:{"value":_vm.uploadData.sign_req,"checked":_vm.uploadData.sign_req},on:{"input":function($event){return _vm.handleRadioCustom('sign_req', $event.target.checked)}}}),_vm._v(" Signature Required ")])])],1):_vm._e(),(_vm.flags.uploaddocsshow)?_c('CRow',{staticClass:"md-2"},[(
                    _vm.uploadData.old_org_doc
                      ? _vm.uploadData.old_org_doc.isDeleted
                      : true
                  )?_c('CCol',{attrs:{"md":"6"}},[_c('label',{staticClass:"required",attrs:{"name":"lbl_upload-document"}},[_vm._v("Upload Document:")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('input',{ref:"fileref",attrs:{"id":"document-upload","name":"upload-document","type":"file","accept":_vm.arraytoComma(_vm.fileType)},on:{"click":function($event){return _vm.OnClickDocument($event)},"change":_vm.selectedFile}})])]):(_vm.uploadData.new_org_doc)?_c('CCol',{attrs:{"md":"12"}},[_c('span',{staticClass:"text-primary",staticStyle:{"cursor":"pointer","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.uploadData.new_org_doc.fileName)+" "),_c('a',{staticClass:"btn hand-icon action-button",on:{"click":function($event){return _vm.deleteDocuments()}}},[_c('i',{staticClass:"fas fa-trash ml-3"})])])]):_c('CCol',{attrs:{"md":"12"}},[_c('span',{staticClass:"text-primary",staticStyle:{"cursor":"pointer","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.uploadData.old_org_doc.document_name)+" "),_c('a',{staticClass:"btn hand-icon action-button",on:{"click":function($event){return _vm.deleteDocuments(
                          _vm.uploadData.old_org_doc.organisation_document_id
                        )}}},[_c('i',{staticClass:"fas fa-trash ml-3"})])])])],1):_vm._e()],1)],1)],1)]}}])})],1)]),(_vm.cropperModal.isShowPopup)?_c('div',[_c('CropperModal',{attrs:{"isShowPopup":_vm.cropperModal.isShowPopup,"fileData":_vm.cropperModal.fileData,"modalColor":"primary","buttonLabel":_vm.cropperModal.buttonLabel,"aspectRatio":_vm.cropperModal.aspectRatio},on:{"modalCallBack":_vm.cropperModalCallBack}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }