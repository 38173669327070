<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      :centered="false"
      size="lg"
      data-keyboard="false"
    >
      <template #header>
        <h6 class="modal-title">
          {{ isEdit ? "Edit Document" : "Add New Document" }}
        </h6>
        <CButtonClose class="text-black" @click="modalCallBack(false)" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmit()"
          :disabled="isSubmitted"
          >Save<CSpinner
            v-if="isSubmitted"
            class="spinner-border-sm text-white m-1"
          />
        </CButton>
      </template>
      <div>
        <ValidationObserver ref="DocumentLib" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)" name="DocumentLib">
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <label
                    name="lbl_classification"
                    class="col-lg-12 col-md-12 required"
                    >Type</label
                  >
                  <CCol md="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="customer_document_type_id"
                        :options="
                          options && options['classification']
                            ? options['classification']
                            : []
                        "
                        :value="uploadData.customer_document_type_id"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                        :disabled="false"
                        @input="handleselect"
                        :showTooltip="true"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label name="lbl_name" class="col-md-12 col-lg-12 required"
                    >Name</label
                  >
                  <CCol md="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="library_name"
                        :value="uploadData.library_name"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <label name="lbl_grouping" class="col-md-12 col-lg-12 required"
                    >Grouping</label
                  >
                  <CCol md="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="category"
                        :options="
                          options && options['grouping']
                            ? options['grouping']
                            : []
                        "
                        :value="uploadData.category"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                        :disabled="false"
                        @input="handleselect"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12 required"
                    >Candidate Type</label
                  >
                  <CCol md="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="candidate_type_ids"
                        :options="
                          options && options['candidate_Type']
                            ? options['candidate_Type']
                            : []
                        "
                        :value="uploadData.candidate_type_ids"
                        :taggable="false"
                        :multiple="true"
                        :clearable="false"
                        :error="errors[0]"
                        :disabled="false"
                        @input="handleselectforcandidateType"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <label name="lbl_candidate_location" class="col-lg-12 col-md-12"
                >Candidate Location</label
              >
              <CCol md="12">
                <RadioButton
                  name="candidate_locality"
                  :value="uploadData.candidate_locality"
                  :options="
                    options && options['candidate_locality']
                      ? options['candidate_locality']
                      : []
                  "
                  @change="handleRadioCustom"
                />
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <label name="lbl_comment" class="col-lg-12 col-md-12"
                    >Comment</label
                  >
                  <CCol md="12">
                    <TextareaInput
                      name="comments"
                      :value="uploadData.comments"
                      @input="handleInput"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol
                md="6"
                v-if="
                  uploadData.customer_document_type_id &&
                  uploadData.customer_document_type_id.description
                "
              >
                <CRow class="mb-3">
                  <label name="lbl_description" class="col-lg-12 col-md-12"
                    >Description</label
                  >
                  <CCol md="12">
                    <div class="note">
                      {{ uploadData.customer_document_type_id.description }}
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CRow class="mb-2">
                  <label name="lbl_rtype" class="col-lg-12 col-md-12 required"
                    >Request Type</label
                  >
                  <CCol md="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="request_type"
                        :value="uploadData.request_type"
                        :options="
                          options && options['request_types']
                            ? options['request_types']
                            : []
                        "
                        @change="handleRadioCustom"
                        :error="errors[0]"
                        :showInfo="true"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow v-if="flags.formshow">
              <CCol md="12">
                <CRow class="mb-2">
                  <label name="lbl_rtype" class="col-lg-12 col-md-12 required"
                    >Form Type</label
                  >
                  <CCol md="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="form_type"
                        :value="uploadData.form_type"
                        :options="
                          options && options['form_types']
                            ? options['form_types']
                            : []
                        "
                        @change="handleRadioCustom"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol md="12" v-if="flags.signatureshow">
                <CRow class="md-2">
                  <CCol md="3">
                    <label>
                      <input
                        id="sign_req"
                        name="sign_req"
                        type="checkbox"
                        class="cursor-pointer ml-2 mr-1"
                        @input="
                          handleRadioCustom('sign_req', $event.target.checked)
                        "
                        :value="uploadData.sign_req"
                        :checked="uploadData.sign_req"
                        :disabled="flags.disableSign"
                      />
                      Response Required
                    </label>
                  </CCol>
                  <CCol md="6" v-if="uploadData.sign_req">
                    <label>
                      <input
                        id="expects_multi_documents"
                        name="expects_multi_documents"
                        type="checkbox"
                        class="cursor-pointer ml-2 mr-1"
                        @input="
                          handleRadioCustom(
                            'expects_multi_documents',
                            $event.target.checked
                          )
                        "
                        :value="uploadData.expects_multi_documents"
                        :checked="uploadData.expects_multi_documents"
                      />
                      Accept Multiple Documents
                    </label>
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6" v-else-if="flags.onlineform">
                <CRow class="mb-2">
                  <label name="lbl_url" class="col-lg-12 col-md-12 required"
                    >URL</label
                  >
                  <CCol md="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="online_form_url"
                        :value="uploadData.online_form_url"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-2" v-if="flags.uploaddocsshow">
              <CCol
                md="12"
                v-if="
                  uploadData.old_org_doc && !uploadData.old_org_doc.isDeleted
                "
              >
                <span
                  class="text-primary"
                  style="cursor: pointer; font-size: 12px"
                >
                  {{ uploadData.old_org_doc.document_name }}
                  <a
                    class="btn hand-icon action-button"
                    @click="
                      deleteDocuments(
                        uploadData.old_org_doc.organisation_document_id
                      )
                    "
                  >
                    <i class="fas fa-trash ml-3"></i>
                  </a>
                </span>
              </CCol>
              <CCol md="12" v-else>
                <CRow>
                  <label class="col-lg-12 col-md-12 required"
                    >Upload Document</label
                  >
                  <CCol md="12">
                    <input
                      id="document-upload"
                      name="upload-document"
                      type="file"
                      ref="fileref"
                      :accept="arraytoComma(fileType)"
                      @change="selectedFile"
                    />
                    <h6
                      class="mt-1 text-muted"
                      v-if="uploadData.customer_document_type_id"
                    >
                      <small
                        >(Please upload documents of type -
                        {{ arraytoComma(fileType) }})</small
                      >
                    </h6>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
    <CropperModal
      v-if="cropperModal.isShowPopup"
      :isShowPopup="cropperModal.isShowPopup"
      :fileData="cropperModal.fileData"
      modalColor="primary"
      :buttonLabel="cropperModal.buttonLabel"
      @modalCallBack="cropperModalCallBack"
      :aspectRatio="cropperModal.aspectRatio"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select";
import TextInput from "@/components/reusable/Fields/TextInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import CheckBox from "@/components/reusable/Fields/CheckBox";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import CropperModal from "@/components/reusable/CropperModal";
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
    libraryInfo: {
      type: Object,
      default: () => null,
    },
    isSubmitted: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    Select,
    TextInput,
    RadioButton,
    CheckBox,
    CropperModal,
    TextareaInput,
  },
  data() {
    return {
      isEdit: false,
      uploadData: {},
      payload: {},
      flags: {
        showdocs: false,
        signatureshow: false,
        formshow: false,
        onlineform: false,
        offlineform: false,
        uploaddocsshow: false,
      },
      cropperModal: {
        isShowPopup: false,
        fileData: null,
        buttonLabel: "Apply",
        aspectRatio: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      "customerDocumentTypes",
      "customerDocTypeCategory",
      "candidateType",
      "getCustomerId",
      "getOrgIDFromAccessToken",
    ]),
    options() {
      return {
        classification: this.sortingType || [],
        grouping: this.groupingOpt || [],
        candidate_Type: this.getcandidateTypeAll || [],
        request_types: [
          {
            id: "document",
            label: "Document",
            info: "A file sent as information. It may require a response and may also need a signature",
          },
          {
            id: "upload_request",
            label: "Upload Request",
            info: "This is actionable by a Candidate and is usually a request to upload information (eg Police Check, passport copy)",
          },
          {
            id: "form",
            label: "Form",
            info: "A request for completion of information. It may be either an online link or paper format",
          },
          {
            id: "custom",
            label: "Custom",
            info: "A document that is uploaded by the Recruiter and is customised to the Candidate and is therefore not in the Library",
          },
          {
            id: "EditableDocs",
            label: "EditableDocs",
            info: "A document that is uploaded by the Recruiter and is customised to the Candidate and is therefore not in the Library",
          },
        ],
        form_types: [
          { id: "online_form", label: "Online Form" },
          { id: "offline_form", label: "Offline Form" },
        ],
        candidate_locality: [
          { code: "local", label: "Local" },
          { code: "international", label: "International" },
          { code: "both", label: "Both" },
        ],
      };
    },
    getcandidateTypeAll() {
      const candidateType = [...this.candidateType];
      return (
        (candidateType.length && [
          { label: "All", code: 0 },
          ...candidateType,
        ]) ||
        []
      );
    },
    groupingOpt() {
      const reLabel = {
        pre_offer: "Pre-Offer",
      };
      return (
        this.customerDocTypeCategory &&
        this.customerDocTypeCategory.map((v) => ({
          code: v,
          label: reLabel[v] ? reLabel[v] : _.startCase(v),
          category: v,
        }))
      );
    },
    classificationOpt() {
      return (
        this.customerDocumentTypes.map((v) => ({
          code: v?.customer_document_type_id,
          label: v?.type_name,
          description: v?.description,
          allowed_extensions: v?.allowed_extensions,
          provider: v?.provider,
        })) || []
      );
    },
    sortingType() {
      return (
        this.classificationOpt?.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        }) || []
      );
    },

    fileType() {
      return (
        this.uploadData?.customer_document_type_id?.allowed_extensions.map(
          (v) => v.toLowerCase()
        ) || []
      );
    },
  },
  methods: {
    ...mapActions(["showToast"]),
    modalCallBack(action, data) {
      this.$emit("modalCallBack", action, data);
    },
    async onSubmit() {
      const isValid = await this.$refs.DocumentLib.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
          autohide: 2000,
        });
        return;
      }
      if (
        this.flags.uploaddocsshow &&
        !(this.payload?.new_org_doc || this.payload?.old_org_doc)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please Choose File!",
          autohide: 2000,
        });
        return;
      }
      const candidate_type_ids =
        this.uploadData?.candidate_type_ids[0].label == "All"
          ? this.candidateType
              ?.filter((val) => val?.label != "All")
              ?.map((val) => val.code)
          : this.uploadData?.candidate_type_ids?.length
          ? this.uploadData?.candidate_type_ids?.map((val) => val.code)
          : [];
      this.payload = {
        ...this.payload,
        candidate_type_ids,
      };
      if (
        this.payload.request_type === "custom" &&
        this.payload.old_org_doc?.organisation_document_id
      ) {
        this.deleteDocuments(
          this.payload.old_org_doc?.organisation_document_id
        );
      }
      this.modalCallBack(true, this.payload);
    },
    handleselect(name, value) {
      Vue.set(this.uploadData, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
      if (name === "customer_document_type_id") {
        // this.handleInput("comments", value?.description);
        this.handleInput("provider", value?.provider);
        if (value?.provider.some((element) => element == "candidate")) {
          const req_type = this.options?.request_types[1]?.id;
          this.handleRadioCustom("request_type", req_type);
        }
      }
    },
    handleInput(name, value) {
      Vue.set(this.uploadData, name, value);
      Vue.set(this.payload, name, value);
    },
    handleselectforcandidateType(name, value) {
      let last_index = value[value.length - 1];
      let val = null;
      if (last_index?.label == "All" || !last_index) {
        Vue.set(this.uploadData, name, [{ code: 0, label: "All" }]);
        val = this.candidateType.map((v) => v.code).filter((v) => v != 0);
        this.payload = {
          ...this.payload,
          [name]: val,
        };
      } else {
        if (value[0].label == "All") value = value.slice(1);
        val = value.map((v) => {
          return v.id || v.code || value;
        });
        Vue.set(this.uploadData, name, value);
        this.payload = {
          ...this.payload,
          [name]: val,
        };
      }
    },
    handleRadioCustom(name, value) {
      let v = null;
      if (value && (value.id === false || value.code === false)) {
        v = false;
      } else {
        v = value?.id || value?.code || value;
      }
      Vue.set(this.uploadData, name, v);
      this.payload = {
        ...this.payload,
        [name]: v,
      };

      if (name === "request_type") {
        this.handleRadioCustom("form_type", null);
        this.handleRadioCustom("sign_req", false);
        this.handleInput("online_form_url", null);
      }

      if (name === "request_type" && value) {
        switch (value) {
          case "document":
            this.flags = {
              showdocs: false,
              signatureshow: true,
              formshow: false,
              onlineform: false,
              offlineform: false,
              uploaddocsshow: true,
              offlineshow: false,
              disableSign: false,
            };
            break;
          case "upload_request":
            this.flags = {
              showdocs: false,
              signatureshow: true,
              formshow: false,
              onlineform: false,
              offlineform: false,
              uploaddocsshow: false,
              offlineshow: false,
              disableSign: true,
            };
            this.handleRadioCustom("sign_req", true);
            break;
          case "form":
            this.flags = {
              showdocs: false,
              signatureshow: false,
              formshow: true,
              offlineshow: true,
              onlineform: false,
              offlineform: true,
              uploaddocsshow: false,
              disableSign: false,
            };
            break;
          case "custom":
            this.flags = {
              showdocs: false,
              signatureshow: false,
              formshow: false,
              offlineshow: false,
              onlineform: false,
              offlineform: false,
              uploaddocsshow: false,
              disableSign: false,
            };
            break;
          case "EditableDocs":
            this.flags = {
              showdocs: false,
              signatureshow: true,
              formshow: false,
              onlineform: false,
              offlineform: false,
              uploaddocsshow: true,
              offlineshow: false,
              disableSign: false,
            };
            break;
          default:
            this.flags = {
              showdocs: false,
              signatureshow: false,
              formshow: false,
              offlineshow: false,
              onlineform: false,
              offlineform: false,
              uploaddocsshow: false,
              disableSign: false,
            };
        }
      } else if (name === "form_type" && value) {
        switch (value) {
          case "online_form":
            this.flags = {
              showdocs: false,
              signatureshow: false,
              formshow: true,
              onlineform: true,
              offlineform: false,
              uploaddocsshow: false,
              offlineshow: false,
              disableSign: false,
            };
            break;
          case "offline_form":
            this.flags = {
              showdocs: false,
              signatureshow: false,
              formshow: true,
              onlineform: false,
              offlineform: true,
              uploaddocsshow: false,
              offlineshow: false,
              disableSign: false,
            };
            break;
          default:
            this.flags = {
              showdocs: false,
              signatureshow: false,
              formshow: false,
              offlineshow: false,
              onlineform: false,
              offlineform: false,
              uploaddocsshow: false,
              disableSign: false,
            };
        }
      }
      if (this.flags.signatureshow && ["request_type"].includes(name)) {
        const isActionable =
          this.uploadData?.request_type === "upload_request"
            ? true
            : this.uploadData?.customer_document_type_id?.provider ===
                "candidate" || false;
        this.handleRadioCustom("sign_req", isActionable);
      }
      if (name === "sign_req" && !value) {
        this.handleRadioCustom("expects_multi_documents", false);
      }
    },
    arraytoComma(d) {
      return d?.map((v) => "." + v).join(", ");
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return parts[parts.length - 1];
    },
    selectedFile(event) {
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        const fileExt = this.getExtension(file.name);
        const fileTypeString = this.fileType;
        if (fileTypeString.includes(fileExt)) {
          if (file.type.startsWith("image/")) {
            this.cropperModal.fileData = file;
            this.cropperModal.isShowPopup = true;
          } else {
            this.payload = {
              ...this.payload,
              new_org_doc: {
                file,
                fileName: file?.name,
              },
            };
          }
        } else {
          let fileTypeError = this.arraytoComma(fileTypeString);
          this.$refs.fileref.value = "";
          this.showToast({
            class: "bg-danger text-white",
            message: `Allowed File Type ${fileTypeError}`,
          });
          return;
        }
      } else {
        this.$refs.fileref.value = "";
        this.showToast({
          class: "bg-danger text-white",
          message: "File too Big, please select a file less than 2mb",
        });
        return;
      }
    },
    cropperModalCallBack(action, data) {
      if (action === false) {
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
        this.$refs.fileref.value = "";
      } else {
        let { fileBlob: file, fileName } = data;
        this.payload = {
          ...this.payload,
          new_org_doc: {
            file,
            fileName,
          },
        };
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
      }
    },
    populateData(item) {
      const category = this.groupingOpt.filter(
        ({ code }) => code === item?.category
      );
      this.handleselect("category", category.length ? category[0] : null);
      const customer_document_type_id = this.classificationOpt.filter(
        ({ code }) => code === item?.customer_document_type_id
      );
      this.handleselect(
        "customer_document_type_id",
        customer_document_type_id.length ? customer_document_type_id[0] : null
      );
      const candidateType =
        this.candidateType?.length == item?.candidate_type_ids?.length
          ? [{ code: 0, label: "All" }]
          : this.candidateType.filter((val) =>
              item?.candidate_type_ids?.includes(val.code)
            ) || [];
      this.handleselectforcandidateType("candidate_type_ids", candidateType);
      this.handleInput("comments", item?.comments);
      this.handleRadioCustom("request_type", item?.request_type);
      this.handleRadioCustom("sign_req", item?.sign_req);
      this.handleInput("online_form_url", item?.online_form_url);
      this.handleInput("library_name", item?.library_name);
      const form_type =
        item?.request_type === "Form"
          ? item?.online_form_url
            ? "Online Form"
            : "Offline Form"
          : null;
      this.handleRadioCustom("form_type", form_type);
      if (item?.organisation_document_id) {
        const old_org_doc = {
          ...item.organisation_document,
          isDeleted: false,
        };
        Vue.set(this.uploadData, "old_org_doc", old_org_doc);
        this.payload = {
          ...this.payload,
          old_org_doc,
        };
      }
      this.payload.document_library_id = item?.document_library_id;
      this.handleRadioCustom(
        "expects_multi_documents",
        item?.expects_multi_documents || false
      );
      this.handleRadioCustom("candidate_locality",item?.candidate_locality)
    },
    deleteDocuments(organisation_document_id) {
      if (organisation_document_id) {
        const old_org_doc = {
          ...this.uploadData.old_org_doc,
          isDeleted: true,
        };
        Vue.set(this.uploadData, "old_org_doc", old_org_doc);
        this.payload = {
          ...this.payload,
          old_org_doc,
        };
      } else {
        this.payload = {
          ...this.payload,
          new_org_doc: null,
        };
      }
    },
  },
  mounted() {
    if (this.libraryInfo && this.libraryInfo.document_library_id) {
      this.isEdit = true;
      this.populateData(this.libraryInfo);
    } else {
      const req_type = this.options?.request_types[0]?.id;
      this.handleRadioCustom("request_type", req_type);
    }
  },
};
</script>
<style lang="scss" scoped>
.note {
  min-height: 55px;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  background-color: #f5f5f5;
  border: 1px solid #d8dbe0 !important;
}
</style>
