<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      :centered="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">
          {{ isEdit ? "Edit Document Type" : "Add New Document Type" }}
        </h6>
        <CButtonClose class="text-black" @click="modalCallBack(false)" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit()"
          >Save
        </CButton>
      </template>
      <div>
        <ValidationObserver ref="formDocumentType" v-slot="{ handleSubmit }">
          <form
            @submit.prevent="handleSubmit(onSubmit)"
            name="formDocumentType"
          >
            <CRow>
              <CCol md="6">
                <CRow class="mb-2">
                  <label name="lbl_type" class="col-lg-12 col-md-12 required"
                    >Type</label
                  >
                  <CCol md="12">
                    <ValidationProvider
                      ref="classification"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <TextInput
                        name="type_name"
                        :value="uploadData.type_name"
                        @input="handleInput"
                        @change="checkReserved"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="mb-2">
                  <label
                    name="lbl_provider"
                    class="col-lg-12 col-md-12 required"
                    >Provider</label
                  >
                  <CCol md="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="provider"
                        :options="
                          options && options['provider']
                            ? options['provider']
                            : []
                        "
                        :value="uploadData.provider"
                        :taggable="false"
                        :multiple="true"
                        :clearable="false"
                        :error="errors[0]"
                        :disabled="false"
                        @input="handleSelect"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="mb-2">
                  <label
                    name="lbl_extension"
                    class="col-lg-12 col-md-12 required"
                    >Allowed File Formats</label
                  >
                  <CCol md="6">
                    <CRow class="mb-2">
                      <label>
                        <input
                          name="is_all_text"
                          type="checkbox"
                          class="cursor-pointer mx-1 ml-3"
                          @input="
                            handleCheckExt('is_all_text', $event.target.checked)
                          "
                          :value="uploadData.is_all_text"
                          :checked="uploadData.is_all_text"
                        />
                        All Text
                      </label>
                    </CRow>
                  </CCol>
                  <CCol md="6">
                    <CRow class="mb-2">
                      <label>
                        <input
                          name="is_all_images"
                          type="checkbox"
                          class="cursor-pointer mx-1 ml-3"
                          @input="
                            handleCheckExt('is_all_images', $event.target.checked)
                          "
                          :value="uploadData.is_all_images"
                          :checked="uploadData.is_all_images"
                        />
                        All Images
                      </label>
                    </CRow>
                  </CCol>
                  <CCol md="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="allowed_extensions"
                        :options="
                          options && options['extension']
                            ? options['extension']
                            : []
                        "
                        :value="uploadData.allowed_extensions"
                        :taggable="false"
                        :multiple="true"
                        :clearable="true"
                        :error="errors[0]"
                        @input="handleChangeMultiSelectAsArray"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>

              <CCol md="6">
                <CRow class="mb-2">
                  <label name="lbl_description" class="col-lg-12 col-md-12"
                    >Description</label
                  >
                  <CCol md="12">
                    <TextAreaInput
                      name="description"
                      :value="uploadData.description"
                      @input="handleInput"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <!-- <CCol md="6">
                <CRow class="mb-2">
                  <label>
                      <input
                        name="is_custom_type"
                        type="checkbox"
                        class="cursor-pointer mx-1 ml-3"
                        @input="
                          handleRadio('is_custom_type', $event.target.checked)
                        "
                        :value="uploadData.is_custom_type"
                        :checked="uploadData.is_custom_type"
                      />
                      Custom
                    </label>
                </CRow>
              </CCol> -->
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select";
import TextInput from "@/components/reusable/Fields/TextInput";
import TextAreaInput from "@/components/reusable/Fields/TextareaInput";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
    classificationInfo: {
      type: Object,
      default: () => null,
    },
    reservedKeys: {
      type: Array,
      default: [],
    },
  },
  components: {
    Select,
    TextInput,
    TextAreaInput,
  },
  data() {
    return {
      uploadData: {allowed_extensions:[]},
      payload: {},
      isEdit: false,
    };
  },
  computed: {
    ...mapGetters(["documentExtensions", "getOrgIDFromAccessToken"]),
    options() {
      return {
        provider: [
          { code: "candidate", label: "Candidate" },
          { code: "facility", label: "Facility" },
        ],
        extension: this.documentExtensions || [],
      };
    },
    isCustomDocumentType() {
      let res = false;
      if (this.uploadData?.type_name)
        res =
          this.uploadData?.type_name.toLowerCase().includes("custom") || false;
      return res;
    },
    isReservedKey() {
      const value = this.uploadData?.type_name;
      const val = value.toLowerCase().trim();
      if (this.isEdit && value === this.classificationInfo.type_name) {
        return false;
      }
      if (this.reservedKeys.includes(val)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["showToast"]),
    checkReserved(name, value) {
      if (this.isReservedKey) {
        setTimeout(() => {
          this.$refs.classification.applyResult({
            errors: ["This is Reserved Keyword, Please Type Any other"],
            valid: false,
            failedRules: {},
          });
        }, 10);
        return false;
      }
    },
    handleSelect(name, value) {
      Vue.set(this.uploadData, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
    },
    handleRadio(name, value) {
      let v = null;
      if (value && (value.id === false || value.code === false)) {
        v = false;
      } else {
        v = value?.id || value?.code || value;
      }
      Vue.set(this.uploadData, name, v);
      this.payload = {
        ...this.payload,
        [name]: v,
      };
    },
    handleCheckExt(name, value) {
      let ext_images = [
        { code: 3, label: "PNG" },
        { code: 4, label: "JPG" },
        { code: 5, label: "JPEG" },
        { code: 9, label: "GIF" },
      ];
      let ext_text = [
        { code: 1, label: "PDF" },
        { code: 2, label: "Docx" },
        { code: 6, label: "Doc" },
        { code: 7, label: "RTF" },
        { code: 8, label: "XLSX" },
        { code: 10, label: "XLS" },
      ];
      if(value){
      Vue.set(this.uploadData, name, value);

      let extensions_added_by_check = [] ;

      if (name == "is_all_images" && value) {
         extensions_added_by_check = [
          ...this.uploadData.allowed_extensions,
          ...extensions_added_by_check,
          ...ext_images
        ];
      }
      if (name == "is_all_text" && value) {
        extensions_added_by_check = [
          ...this.uploadData.allowed_extensions,
          ...extensions_added_by_check,
          ...ext_text
        ];
      }
      // Eradicate Duplicates by check box
      let codes =  extensions_added_by_check.map(o => o.code)
      extensions_added_by_check =  extensions_added_by_check.filter(({code}, index) => !codes.includes(code, index + 1))
      this.uploadData.allowed_extensions = extensions_added_by_check
      this.payload = {
        ...this.payload,
        allowed_extensions: this.uploadData.allowed_extensions
          ? this.uploadData.allowed_extensions.map((v) => {
              return v.id || v.code || v;
            })
          : null,
      };
      }else{
        // Remove the values if unchecked
        let remove_check_values = name == 'is_all_images' ? ext_images :ext_text;
        let codes =  remove_check_values.map(o => o.code)
        this.uploadData.allowed_extensions = this.uploadData.allowed_extensions.filter((a)=>{if(!codes.includes(a.code)){return a}})
        this.payload = {
        ...this.payload,
        allowed_extensions: this.uploadData.allowed_extensions
          ? this.uploadData.allowed_extensions.map((v) => {
              return v.id || v.code || v;
            })
          : null,
      };

      }
    },
    handleInput(name, value) {
      Vue.set(this.uploadData, name, value);
      Vue.set(this.payload, name, value);
      if (
        name === "type_name" &&
        this.isCustomDocumentType &&
        this.uploadData.is_custom_type == undefined
      ) {
        this.handleRadio("is_custom_type", true);
      }
    },
   
    handleChangeMultiSelectAsArray(name, value) {
      Vue.set(this.uploadData, name, value);
      this.payload = {
        ...this.payload,
        [name]: value
          ? value.map((v) => {
              return v.id || v.code || v;
            })
          : null,
      };
    },
    async onSubmit() {
      const isValid = await this.$refs.formDocumentType.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
          autohide: 2000,
        });
        return;
      }
      if (this.isReservedKey) {
        this.$refs.classification.applyResult({
          errors: ["This is Reserved Keyword, Please Type Any other"],
          valid: false,
          failedRules: {},
        });
        return;
      }
      let payload = {
        organisation_id: this.getOrgIDFromAccessToken,
        provider: this.payload?.provider?.map((val) => val.code) || null,
        type_name: this.payload?.type_name,
        description: this.payload?.description,
        allowed_extensions: this.payload?.allowed_extensions,
        is_custom_type: this.payload?.is_custom_type || true,
      };
      if (this.isEdit && this.uploadData?.customer_document_type_id) {
        payload = {
          ...payload,
          customer_document_type_id: this.uploadData?.customer_document_type_id,
        };
      }
      this.modalCallBack(true, payload);
    },
    modalCallBack(action, data) {
      this.$emit("modalCallBack", action, data);
    },
  },
  mounted() {
    if (
      this.classificationInfo &&
      this.classificationInfo?.customer_document_type_id
    ) {
      const item = this.classificationInfo;
      this.isEdit = true;
      this.uploadData.customer_document_type_id =
        item?.customer_document_type_id;
      
      this.handleInput("type_name", item?.type_name);
      this.handleRadio("is_custom_type", item?.is_custom_type);

      let extension =
        (item.allowed_extensions &&
          item.allowed_extensions.length &&
          this.documentExtensions.filter(({ label }) =>
            item.allowed_extensions.includes(label)
          )) ||
        [];
     
      this.handleChangeMultiSelectAsArray("allowed_extensions", extension);
      this.handleInput("description", item?.description);
      const provider = item?.provider
        ? item?.provider?.map((val) => ({ code: val, label: _.startCase(val) }))
        : null;
      this.handleSelect("provider", provider);
    }
  },
};
</script>
