<template>
  <div class="document-library">
    <div
      class="d-flex align-content-end justify-content-end"
      v-if="!getIsRecruiterRole"
    >
      <div class="add-action">
        <CIcon
          name="cil-info"
          class="hinticon-multi-select"
          v-c-tooltip="{ content: hint, placement: 'bottom' }"
        />
        <CButton color="primary" @click="openModal(activeTab)">Add</CButton>
      </div>
    </div>
    <CTabs :activeTab="activeTab" @update:activeTab="changeActiveTab">
      <CTab :title="`Library(${customerDocumentLibrary.length})`">
        <OrgDocumentList
          :documents="customerDocumentLibrary"
          :isMobile="isMobile"
          @editDocumentLibrary="editDocumentLibrary"
        />
      </CTab>
      <CTab
        v-if="!getIsRecruiterRole"
        :title="`Types(${this.customerDocumentTypes.length})`"
      >
        <DocumentTypeList
          :customerDocumentTypes="customerDocumentTypes"
          @classificationEdit="classificationEdit"
        />
      </CTab>
    </CTabs>
    <ClassificationModal
      v-if="classificationModal.isShowPopup"
      :isShowPopup="classificationModal.isShowPopup"
      :classificationInfo="classificationModal.classificationInfo"
      :reservedKeys="reservedKeys"
      @modalCallBack="classificationModalCallBack"
      :isSubmitted="isSubmitted"
    />
    <DocumentLibraryModal
      v-if="documentLibraryModal.isShowPopup"
      :isShowPopup="documentLibraryModal.isShowPopup"
      :libraryInfo="documentLibraryModal.libraryInfo"
      @modalCallBack="documentLibraryModalCallBack"
      :isSubmitted="isSubmitted"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import OrgDocumentList from "@/containers/Organisation/Documents/OrgDocumentList";
import DocumentTypeList from "@/containers/Organisation/Documents/DocumentTypeList";
import ClassificationModal from "@/containers/Organisation/Documents/ClassificationModal";
import DocumentLibraryModal from "@/containers/Organisation/Documents/DocumentLibraryModal";

export default {
  components: {
    OrgDocumentList,
    DocumentTypeList,
    ClassificationModal,
    DocumentLibraryModal,
  },
  props: {
    onBoardDocuments: {
      type: Boolean,
      default: false,
    },
    documents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeTab: 0,
      isSubmitted: false,
      small: true,
      striped: true,
      bordered: true,
      isMobile: false,
      classificationModal: {
        isShowPopup: false,
        classificationInfo: null,
      },
      documentLibraryModal: {
        isShowPopup: false,
        libraryInfo: null,
      },
      hint: "To add a new Document Classification - select 'Add' - complete the fields.Go to Library. Insert the document to make it available to your Recruiters",
    };
  },
  computed: {
    ...mapGetters([
      "candidateType",
      "customerDocTypeCategory",
      "getOrgIDFromAccessToken",
      "customerDocumentTypes",
      "customerDocumentLibrary",
      "getCustomerId",
      "getIsRecruiterRole",
    ]),
    reservedKeys() {
      return (
        this.customerDocumentTypes
          .filter(({ reserved }) => reserved)
          .map(({ type_name }) => type_name.toLowerCase().trim()) || []
      );
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "scrollTop",
      "fetchCustomerDocumentTypes",
      "fetchCustomerDocTypeCategory",
      "getDocumentExtensions",
      "fetchDocumentExtension",
      "fetchCandidateType",
      "fetchCustomerDocumentLib",
      "createCustomerDocumentLibrary",
      "uploadFileToOrganisationDoc",
      "updateCustomerDocType",
      "createCustomerDocType",
      "updateCustomerDocumentLibrary",
      "deleteOrganisationDocument",
    ]),
    changeActiveTab(tab) {
      this.activeTab = tab;
    },
    openModal(activeTab) {
      if (activeTab === 0) {
        this.documentLibraryModal.isShowPopup = true;
        this.documentLibraryModal.libraryInfo = null;
      } else if (activeTab === 1) {
        this.classificationModal.isShowPopup = true;
        this.classificationModal.classificationInfo = null;
      }
    },
    documentLibrary({ organisation_document_id, payload }) {
      const organisation_id = this.getOrgIDFromAccessToken;
      const {
        category,
        library_name,
        request_type,
        customer_document_type_id,
        comments,
        sign_req,
        online_form_url,
        candidate_type_ids,
        document_library_id,
        expects_multi_documents,
        candidate_locality,
      } = payload;
      let finalPayload = {
        category,
        library_name,
        request_type,
        customer_uid: this.getCustomerId,
        organisation_id,
        customer_document_type_id,
        organisation_document_id,
        comments,
        candidate_type_ids,
        sign_req,
        online_form_url,
        expects_multi_documents,
        candidate_locality,
      };
      let appendAction = [];
      if (document_library_id) {
        finalPayload = {
          ...finalPayload,
          document_library_id,
        };
        appendAction = [this.updateCustomerDocumentLibrary(finalPayload)];
      } else {
        appendAction = [this.createCustomerDocumentLibrary(finalPayload)];
      }

      return Promise.all(appendAction).then((res) => {
        this.isSubmitted = false;
        this.documentLibraryModal.isShowPopup = false;
        this.documentLibraryModal.libraryInfo = null;
      });
    },
    async upload(payload) {
      this.isSubmitted = true;
      const organisation_id = this.getOrgIDFromAccessToken;
      if (organisation_id) {
        let appendAction = [];
        if (payload?.new_org_doc) {
          const data = {
            file: payload?.new_org_doc?.file,
            comments: payload?.comments,
            customer_document_type_id: payload?.customer_document_type_id,
            fileName: payload?.new_org_doc?.fileName,
          };
          appendAction = [
            ...appendAction,
            this.uploadFileToOrganisationDoc(data),
          ];
        }

        const res = await Promise.all(appendAction);
        if (res && (payload.file ? res.length : true)) {
          let organisation_document_id =
            (res.length && res[0].organisation_document_id) || null;
          if (payload?.old_org_doc && payload?.old_org_doc?.isDeleted) {
            await this.deleteOrganisationDocument(
              payload?.old_org_doc?.organisation_document_id
            );
          } else if (
            !organisation_document_id &&
            payload?.old_org_doc &&
            !payload?.old_org_doc?.isDeleted
          ) {
            organisation_document_id =
              payload?.old_org_doc?.organisation_document_id;
          }
          this.documentLibrary({ payload, organisation_document_id });
        }
      } else {
        this.isSubmitted = false;
        this.showToast({
          class: "bg-danger text-white",
          message: "Error while Add Document!",
        });
        return;
      }
    },
    classificationModalCallBack(action, data) {
      if (action && data) {
        let appendAction = [];
        if (data.customer_document_type_id) {
          appendAction = [this.updateCustomerDocType(data)];
        } else {
          appendAction = [this.createCustomerDocType(data)];
        }
        this.isSubmitted = true;
        Promise.all(appendAction).then((res) => {
          this.classificationModal.isShowPopup = false;
          this.classificationModal.classificationInfo = null;
          this.isSubmitted = false;
        });
      } else {
        this.classificationModal.isShowPopup = false;
        this.classificationModal.classificationInfo = null;
      }
    },
    classificationEdit(data) {
      this.classificationModal.isShowPopup = true;
      this.classificationModal.classificationInfo = data;
    },
    documentLibraryModalCallBack(action, data) {
      if (action && data) {
        this.upload(data);
      } else {
        this.documentLibraryModal.isShowPopup = false;
        this.documentLibraryModal.libraryInfo = null;
      }
    },
    editDocumentLibrary(data) {
      this.documentLibraryModal.libraryInfo = data;
      this.documentLibraryModal.isShowPopup = true;
    },
  },
  mounted() {
    this.scrollTop();
    this.isMobile = window.matchMedia(
      "only screen and (max-width: 760px)"
    ).matches;

    let appendAction = [];
    appendAction = [...appendAction, this.fetchCustomerDocumentTypes()];
    appendAction = [...appendAction, this.fetchCustomerDocTypeCategory()];
    appendAction = [...appendAction, this.fetchDocumentExtension()];
    appendAction = [
      ...appendAction,
      this.fetchCustomerDocumentLib({ allow_override_delete: true }),
    ];
    if (!this.candidateType.length)
      appendAction = [...appendAction, this.fetchCandidateType()];
    Promise.all(appendAction).then((res) => {});
  },
};
</script>
<style lang="scss" scoped>
.margin-2 {
  margin-bottom: 0.8rem;
}
.action-button {
  width: 20px;
  height: 20px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}
.hand-icon {
  color: #3c4b64;
  cursor: pointer;
}
.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
  width: 1.5rem;
  height: 1.5rem;
  color: #3c4b64;
}
.ml-4 {
  margin-left: 4.5rem !important;
}
.action-btn {
  width: 165px !important;
}
.add-action {
  margin-left: auto;
  margin-right: 0;
}
@media all and (min-width: 760px) {
  .add-action {
    position: absolute;
    right: 1.25rem;
    z-index: 1000;
  }
}
</style>
