<template>
  <div class="pb-2 mt-2">
    <CTabs>
      <CTab :title="`job(${jobDocuments.length})`">
        <DocumentList
          :documents="jobDocuments"
          :isMobile="isMobile"
          @editDocumentLibrary="editDocumentLibrary"
        />
      </CTab>
      <CTab :title="`Pre-Offer(${preOfferDocuments.length})`">
        <DocumentList
          :documents="preOfferDocuments"
          :isMobile="isMobile"
          @editDocumentLibrary="editDocumentLibrary"
        />
      </CTab>
      <CTab :title="`Offer(${offerDocuments.length})`">
        <DocumentList
          :documents="offerDocuments"
          :isMobile="isMobile"
          @editDocumentLibrary="editDocumentLibrary"
        />
      </CTab>
      <CTab :title="`Licensing(${licensingDocuments.length})`">
        <DocumentList
          :documents="licensingDocuments"
          :isMobile="isMobile"
          @editDocumentLibrary="editDocumentLibrary"
        />
      </CTab>
     
      <CTab :title="`Visa(${visaDocuments.length})`">
        <DocumentList
          :documents="visaDocuments"
          :isMobile="isMobile"
          @editDocumentLibrary="editDocumentLibrary"
        />
      </CTab>
      <CTab :title="`Joining(${joiningDocuments.length})`">
        <DocumentList
          :documents="joiningDocuments"
          :isMobile="isMobile"
          @editDocumentLibrary="editDocumentLibrary"
        />
      </CTab>
      <CTab :title="`Additional(${additionalDocuments.length})`">
        <DocumentList
          :documents="additionalDocuments"
          :isMobile="isMobile"
          @editDocumentLibrary="editDocumentLibrary"
        />
      </CTab>
      <CTab :title="`All(${documents.length})`">
        <DocumentList
          :documents="documents"
          :isMobile="isMobile"
          @editDocumentLibrary="editDocumentLibrary"
          :isAll="true"
        />
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import PreviewModal from "@/components/reusable/PreviewModal";
import DocumentInfoEdit from "@/containers/CandidateDetailsTalentFind/EditForm/DocumentInfoEdit";
import Modal from "@/components/reusable/Modal";
import DocumentList from "@/containers/Organisation/Documents/DocumentList";

export default {
  props: {
    documents: { type: Array, default: () => [] },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PreviewModal,
    DocumentInfoEdit,
    Modal,
    DocumentList,
  },
  computed: {
    jobDocuments() {
      return this.documents.filter((v) => v.category === "job");
    },
    preOfferDocuments() {
      return this.documents.filter((v) => v.category === "pre_offer");
    },
    offerDocuments() {
      return this.documents.filter((v) => v.category === "offer");
    },
    visaDocuments() {
      return this.documents.filter((v) => v.category === "visa");
    },
    joiningDocuments() {
      return this.documents.filter((v) => v.category === "joining");
    },
    additionalDocuments() {
      return this.documents.filter((v) => v.category === "additional");
    },
    licensingDocuments() {
      return this.documents.filter((v) => v.category === "licensing");
    }
  },
  methods: {
    editDocumentLibrary(data) {
      this.$emit("editDocumentLibrary", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.preview {
  .iframeclass {
    width: 75%;
  }
  .modalPdfContent {
    height: 350px !important;
    overflow-y: scroll;
  }
  .wrapper {
    -webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -ms-overflow-scrolling: touch;
  }
  @media all and (max-width: 480px) {
    .iframeclass {
      width: auto;
    }
  }
}
.action-button {
  width: 14px;
  height: 14px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}
.hand-icon {
  color: #3c4b64;
  cursor: pointer;
}
.f-13 {
  font-size: 13px;
}
.table-data-break {
  word-wrap: break-word;
  width: 15%;
}
.table-data-name {
  word-wrap: break-word;
  width: 25%;
}
</style>
