<template>
  <div>
    <div v-if="isMobile">
      <div v-for="document in library" :key="document.document_id">
        <div class="pb-1 border-bottom pt-2 pb-2 d-flex">
          <div style="min-width: 90%">
            <CRow v-if="isAll" class="mb-2">
              <CCol col="5" class="text-gray">Grouping</CCol>
              <CCol col="7" class="h6 fw-600">{{ document.category }}</CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol col="5" class="text-gray">Name</CCol>
              <CCol col="7" class="h6 fw-600">{{ document.library_name }}</CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol col="5" class="text-gray">Type</CCol>
              <CCol col="7" class="h6 fw-600">{{
                document.customer_document_type
              }}</CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol col="5" class="text-gray">Candidate Type</CCol>
              <CCol col="7" class="h6 fw-600">{{
                document.candidate_type
              }}</CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol col="5" class="text-gray">Comment</CCol>
              <CCol col="7" class="h6 fw-600">{{ document.decription }}</CCol>
            </CRow>
            <CRow class="mb-2">
              <CCol col="5" class="text-gray">Request Type</CCol>
              <CCol col="7" class="h6 fw-600">{{ document.request_type }}</CCol>
            </CRow>
            <!-- <CRow class="mb-2">
              <CCol col="5" class="text-gray">Last Updated</CCol>
              <CCol col="7" class="h6 fw-600">{{ document.last_updated }}</CCol>
            </CRow> -->
          </div>
          <div class="d-flex flex-column justify-content-center">
            <CButton
              type="button"
              @click="fileDownload(document, document.document_id)"
            >
              <i class="fas fa-download"></i>
            </CButton>
            <CButton type="button" @click="preview(document)">
              <i class="fas fa-eye"></i>
            </CButton>
            <CButton
              type="button"
              @click="editDocument(document.item)"
              v-if="!getIsRecruiterRole && !document.is_deleted"
            >
              <i class="fas fa-edit"></i>
            </CButton>
            <CButton
              type="button"
              @click="deleteDocuments(document)"
              v-if="!getIsRecruiterRole && !document.is_deleted"
            >
              <i class="fas fa-trash"></i>
            </CButton>
          </div>
        </div>
      </div>
      <div v-if="!documents.length">
        <p class="text-center text-gray">No Documents Found</p>
      </div>
    </div>
    <div v-else>
      <CDataTable
        :bordered="true"
        :striped="true"
        :small="true"
        :responsive="true"
        :items="library"
        :fields="isAll ? fieldsAllCustom : fieldsCustom"
        :items-per-page="10"
        :tableFilter="{
          label: 'Search',
          placeholder: 'Type for search',
          position: 'right',
        }"
        sorter
        pagination
      >
        <template #download="{ item }">
          <td class="py-2">
            <button
              :disabled="
                disabledBtnDocID.includes(item.organisation_document_id)
              "
              class="hand-icon action-button"
              v-if="item && item.link"
              title="Download"
              @click="fileDownload(item, item.organisation_document_id)"
            >
              <i class="fas fa-download ml-3"></i>
            </button>
            <span v-else class="ml-3">--</span>
          </td>
        </template>
        <template #preview="{ item }">
          <td class="py-2">
            <a
              class="hand-icon"
              v-if="item && item.link"
              title="Preview"
              @click="preview(item)"
            >
              <i class="fas fa-eye ml-3"></i>
            </a>
            <span v-else class="ml-3">--</span>
          </td>
        </template>
        <template #action="{ item }">
          <td class="py-2" v-if="!item.is_deleted">
            <a
              class="hand-icon"
              title="Edit"
              v-if="item"
              @click="editDocument(item.item)"
            >
              <i class="fas fa-edit ml-3"></i>
            </a>
            <span v-else>--</span>
            <a
              class="hand-icon"
              title="Delete"
              v-if="item"
              @click="deleteDocuments(item)"
            >
              <i class="fas fa-trash ml-3"></i>
            </a>
            <span v-else>--</span>
          </td>
          <td v-else></td>
        </template>
      </CDataTable>
    </div>
    <EditDocumentLibrary
      :isShowPopup="editModal.isShowPopup"
      :documentInfo="editModal.documentInfo"
      @modalCallBack="editModalCallBack"
      :isLoader="editModal.isLoader"
      v-if="editModal.isShowPopup"
    />
    <PreviewModal
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="modalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
      v-if="previewModal.isShowPopup"
    />
    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deletemodalCallBack"
      :closeOnBackdrop="false"
      v-if="deleteConfirmModal.isShowPopup"
    />
  </div>
</template>
<script>
import PreviewModal from "@/components/reusable/PreviewModal";
import pdf from "vue-pdf";
import { BASE_URL } from "@/service_urls";
import { mapActions, mapGetters } from "vuex";
import { Role, getScope } from "@/helpers/helper";
import Modal from "@/components/reusable/Modal";
import { getLocalOrgDetail } from "@/helpers/helper";
import EditDocumentLibrary from "@/containers/Organisation/Documents/EditDocumentLibrary";
import _ from "lodash";
import m from "moment";
export default {
  props: {
    documents: { type: Array, default: () => [] },
    isMobile: {
      type: Boolean,
      default: false,
    },
    isAll: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PreviewModal,
    Modal,
    EditDocumentLibrary,
  },
  data() {
    return {
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      editModal: {
        isShowPopup: false,
        documentInfo: null,
        isLoader: false,
      },
      Role,
      currentUserRole: getScope(),
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        data: null,
      },
      fields: [
        {
          key: "library_name",
          label: "Name",
          _style: "width:20%",
          filter: true,
        },
        {
          key: "customer_document_type",
          label: "Type",
          _style: "width:8%",
          filter: true,
        },
        {
          key: "candidate_type",
          label: "Candidate Type",
          _style: "width:12%",
          filter: true,
        },
        {
          key: "decription",
          label: "Comment",
          _style: "width:20%",
          filter: false,
        },
        {
          key: "request_type",
          label: "Request Type",
          _style: "width:10%",
          filter: true,
        },
        {
          key: "download",
          label: "Download",
          _style: "width:5%",
          filter: false,
        },
        { key: "preview", label: "Preview", _style: "width:5%", filter: false },
        {
          key: "action",
          label: "Edit/Delete",
          _style: "width:10%",
          sorter: false,
          filter: false,
        },
        {
          key: "last_updated",
          label: "Last Updated",
          _style: "width:10%",
          sorter: false,
          filter: false,
        },
      ],
      fieldsAll: [
        {
          key: "category",
          label: "Grouping",
          _style: "width:20%",
          filter: true,
        },
        {
          key: "library_name",
          label: "Name",
          _style: "width:20%",
          filter: true,
        },
        {
          key: "customer_document_type",
          label: "Classification",
          _style: "width:8%",
          filter: true,
        },
        {
          key: "candidate_type",
          label: "Candidate Type",
          _style: "width:12%",
          filter: true,
        },
        {
          key: "decription",
          label: "Comment",
          _style: "width:15%",
          filter: false,
        },
        {
          key: "request_type",
          label: "Request Type",
          _style: "width:5%",
          filter: true,
        },
        {
          key: "download",
          label: "Download",
          _style: "width:5%",
          filter: false,
        },
        { key: "preview", label: "Preview", _style: "width:5%", filter: false },
        {
          key: "action",
          label: "Edit/Delete",
          _style: "width:10%",
          sorter: false,
          filter: false,
        },
        {
          key: "last_updated",
          label: "Last Updated",
          _style: "width:10%",
          sorter: false,
          filter: false,
        },
      ],
      edit_doc_id: null,
      disabledBtnDocID: [],
    };
  },
  computed: {
    ...mapGetters([
      "candidateType",
      "getOrgIDFromAccessToken",
      "isJobSupplierFromAccessToken",
      "getIsRecruiterRole",
    ]),
    fieldsCustom() {
      if (this.getIsRecruiterRole)
        return this.fields.filter(({ key }) => key != "action");
      return this.fields;
    },
    fieldsAllCustom() {
      if (this.getIsRecruiterRole)
        return this.fieldsAll.filter(({ key }) => key != "action");
      return this.fieldsAll;
    },
    library() {
      const reLabel = {
        pre_offer: "Pre-Offer",
      };
      return (
        this.documents &&
        this.documents
          .map((v) => ({
            library_name: v?.library_name || "--",
            category: reLabel[v?.category]
              ? reLabel[v?.category]
              : _.startCase(v?.category) || "--",
            customer_document_type:
              v?.customer_document_type?.type_name || "--",
            candidate_type: this.getCandidateTypesByIds(v?.candidate_type_ids),
            decription: v?.comments || "--",
            request_type: _.startCase(v?.request_type) || "--",
            link: v?.organisation_document?.link_to_doc,
            organisation_document_id: v?.organisation_document_id,
            item: v,
            last_updated:
              v?.deleted_by_user_name && v?.deleted_on
                ? `Deleted by ${v?.deleted_by_user_name} on ${this.formatDate(
                    v?.deleted_on,
                    "DD MMM YYYY hh:mm A"
                  )}`
                : v?.last_modified_on && v?.last_modified_by_user_name
                ? `${v?.last_modified_by_user_name} on ${this.formatDate(
                    v?.last_modified_on,
                    "DD MMM YYYY hh:mm A"
                  )}`
                : `${v?.created_by_user_name} on ${this.formatDate(
                    v?.created_on,
                    "DD MMM YYYY hh:mm A"
                  )}`,
            is_deleted: v?.deleted || v?.deleted_by,
          }))
          .map((item) => {
            return {
              ...item,
              _classes: [item._classes, item.is_deleted && "text-danger"],
            };
          })
      );
    },
    isEditable() {
      return (
        ([Role.customerAdmin, Role.customerRecruiter].includes(
          this.currentUserRole
        ) &&
          !this.isJobSupplierFromAccessToken) ||
        false
      );
    },
    isCustomerAdmin() {
      return this.currentUserRole === this.Role.customerAdmin;
    },
    isCandidateRole() {
      return (
        this.currentUserRole === Role.customerCandidate ||
        this.currentUserRole === Role.systemCandidate
      );
    },
    getcandidateTypeAll() {
      const candidateType = [...this.candidateType];
      return (
        (candidateType.length && [
          { label: "All", code: 0 },
          ...candidateType,
        ]) ||
        []
      );
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "downloadDocument",
      "deleteOrganisationDocument",
      "uploadFileToOrganisationDoc",
      "updateCustomerDocumentLibrary",
      "fetchCustomerDocumentLib",
      "deleteCustomerDocumentLib",
    ]),
    formatDate(date, formatString = "DD MMM YYYY") {
      if (date) return m(date).format(formatString);
      return null;
    },
    getCandidateTypesByIds(candidate_type_ids) {
      const candiateTypes =
        this.getcandidateTypeAll.filter(({ code }) =>
          candidate_type_ids.includes(code)
        ) || [];
      const differenceWith = this.getcandidateTypeAll.filter(
        (v) => !candiateTypes.some(({ code }) => code === v?.code)
      );

      return differenceWith.length === 1 && differenceWith[0].code === 0
        ? "All"
        : candiateTypes.map(({ label }) => label).join() || "--";
    },
    async upload(payload) {
      const organisation_id = this.getOrgIDFromAccessToken;
      if (organisation_id) {
        let appendAction = [];
        if (payload?.new_org_doc?.file) {
          const data = {
            file: payload?.new_org_doc?.file,
            comments: payload?.document_description,
            customer_document_type_id: payload?.customer_document_type_id,
            fileName: payload?.new_org_doc?.fileName,
          };
          appendAction = [
            ...appendAction,
            this.uploadFileToOrganisationDoc(data),
          ];
        }

        const res = await Promise.all(appendAction);
        if (res && (payload.new_org_doc ? res.length : true)) {
          let organisation_document_id =
            res.length && res[0].organisation_document_id;
          if (payload?.old_org_doc && payload?.old_org_doc?.isDeleted) {
            await this.deleteOrganisationDocument(
              payload?.old_org_doc?.organisation_document_id
            );
          } else if (
            !organisation_document_id &&
            payload?.old_org_doc &&
            !payload?.old_org_doc?.isDeleted
          ) {
            organisation_document_id =
              payload?.old_org_doc?.organisation_document_id;
          }
          organisation_document_id = organisation_document_id
            ? organisation_document_id
            : null;
          await this.documentLibrary({ payload, organisation_document_id });
        }
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: "Error while Add Document!",
        });
        return;
      }
    },
    documentLibrary({ organisation_document_id, payload }) {
      const organisation_id = this.getOrgIDFromAccessToken;
      const {
        category,
        request_type,
        customer_document_type_id,
        document_description,
        sign_req,
        online_form_url,
        candidate_type_ids,
        document_library_id,
      } = payload;
      const finalPayload = {
        category,
        request_type,
        customer_uid: this.getCustomerId,
        organisation_id,
        customer_document_type_id,
        organisation_document_id,
        document_description,
        candidate_type_ids,
        sign_req,
        online_form_url,
        document_library_id,
      };

      return this.updateCustomerDocumentLibrary(finalPayload).then((res) => {
        this.fetchCustomerDocumentLib({ allow_override_delete: true});
        this.editModal = {
          isShowPopup: false,
          documentInfo: null,
          isLoader: false,
        };
      });
    },
    async editModalCallBack(action, payload) {
      if (action) {
        this.editModal.isLoader = true;
        this.upload(payload).then((res) => {
          this.editModal.isShowPopup = false;
        });
      } else {
        this.editModal.isShowPopup = false;
      }
    },
    editDocument(data) {
      // this.editModal.isShowPopup = true;
      // this.editModal.documentInfo = data;
      this.$emit("editDocumentLibrary", data);
    },
    fileDownload(data, docId) {
      if (docId) this.disabledBtnDocID.push(docId);
      let { link, name: document_name } = data;
      let url = link.replace("/api/v1/", "");
      this.downloadDocument({ url, document_name }).finally(() => {
        if (docId) {
          this.disabledBtnDocID = this.disabledBtnDocID.filter(
            (i) => i != docId
          );
        }
      });
      return false;
    },
    preview(data) {
      const {
        item: { organisation_document },
      } = data;
      var _baseUrl = BASE_URL;
      // var _base_domain = _baseUrl.substring(0, _baseUrl.indexOf("/api"));
      var _base_domain = "https://" + window.location.host;
      const ext = organisation_document?.document_ext?.document_ext;
      if (["PNG", "JPG", "JPEG", "GIF"].includes(ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = _base_domain + organisation_document.link_to_doc;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(
          _base_domain + organisation_document.link_to_doc
        );
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(ext)) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          _base_domain +
          organisation_document.link_to_doc +
          "";
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    modalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    editInfo(data) {
      this.EditModal.isShowPopup = true;
      this.EditModal.documentInfo = data;
      return false;
    },
    deleteDocuments(data) {
      const { item } = data;
      this.deleteConfirmModal.modalContent = `Do you want to delete this document - ${item?.library_name} - ${data?.request_type} ?`;
      this.deleteConfirmModal.data = item;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deletemodalCallBack(action) {
      if (action === "Confirm") {
        let document_library_id =
          this.deleteConfirmModal.data.document_library_id;
        this.deleteCustomerDocumentLib(document_library_id).then((res) => {
          this.fetchCustomerDocumentLib({allow_override_delete:true});
          this.deleteConfirmModal.isShowPopup = false;
          this.deleteConfirmModal.data = null;
        });
      } else {
        this.deleteConfirmModal.isShowPopup = false;
        this.deleteConfirmModal.data = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.preview {
  .iframeclass {
    width: 75%;
  }
  .modalPdfContent {
    height: 350px !important;
    overflow-y: scroll;
  }
  .wrapper {
    -webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -ms-overflow-scrolling: touch;
  }
  @media all and (max-width: 480px) {
    .iframeclass {
      width: auto;
    }
  }
}
.action-button {
  width: 14px;
  height: 14px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}
.hand-icon {
  color: #3c4b64;
  cursor: pointer;
}
.f-13 {
  font-size: 13px;
}
.table-data-break {
  word-wrap: break-word;
  width: 15%;
}
.table-data-name {
  word-wrap: break-word;
  width: 25%;
}
</style>
