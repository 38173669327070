<template>
  <div class="config-public-job-board container-md col-lg-11 col-md-11">
    <h4 class="text-primary mt-2 mb-4">Document Library</h4>
      <CCard>
        <CCardBody>
          <UploadOrgOnBoardDocs class="pb-3"   />
        </CCardBody>
      </CCard>
  </div>
</template>
<script>
import UploadOrgOnBoardDocs from "@/containers/Organisation/Documents/UploadOnBoardDocuments";
export default {
  components: {
    UploadOrgOnBoardDocs
    
  },
}
</script>
